import React, { useState } from "react";
import nikeService from "../../../services/nikeService";

function SuiteAllianceDemoPage() {
  const [state, setState] = useState({
    name: "",
    email: "",
    companyName: "",
    message: "",
    loader: false,
    successMessage: false,
  });

  const updateState = (obj) => {
    setState((prev) => ({ ...prev, ...obj }));
  };

  const handleInput = (value, type) => {
    updateState({ [type]: value });
  };

  const closeModal = () => {
    updateState({
      name: "",
      email: "",
      companyName: "",
      message: "",
      successMessage: false,
    });
  };

  const bookForDemo = () => {
    $(function () {
      $("#bookForDemo").modal("show");
      $("#name").removeClass("is-invalid");
      $("#email").removeClass("is-invalid");
      $("#companyName").removeClass("is-invalid");
      $("#message").removeClass("is-invalid");
    });
    updateState({
      name: "",
      email: "",
      companyName: "",
      message: "",
      successMessage: false,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitBtn = async (e) => {
    updateState({ loader: true });
    e.preventDefault();
    let error = 0;
    const requiredFields = ["name", "email", "companyName", "message"];
    requiredFields.forEach((field) => {
      if (!state[field].trim()) {
        error++;
        $(`#${field}`).addClass("is-invalid");
        updateState({ loader: false });
      } else {
        $(`#${field}`).removeClass("is-invalid");
      }
    });

    if (!validateEmail(state.email)) {
      error++;
      $("#email").addClass("is-invalid");
      updateState({ loader: false });
    } else {
      $("#email").removeClass("is-invalid");
    }
    if (error == 0) {
      let data = {
        name: state.name,
        email: state.email,
        companyName: state.companyName,
        message: state.message,
        subject: " Interested in SuiteAlliance Program",
        mail: "SuiteAllianceDemoMail",
      };
      //   console.log(data, "data");

      await nikeService.serviceRequest(data);
      updateState({
        name: "",
        email: "",
        companyName: "",
        message: "",
        loader: false,
        successMessage: true,
      });

      //   $("#bookForDemo").modal("hide");
    }
  };

  const sendSuccessmail = async (type) => {
    try {
      updateState({
        name: "",
        email: "",
        companyName: "",
        message: "",
        successMessage: false,
      });

      $(function () {
        $("#bookForDemo").modal("hide");
        $("#name").removeClass("is-invalid");
        $("#email").removeClass("is-invalid");
        $("#companyName").removeClass("is-invalid");
        $("#message").removeClass("is-invalid");
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="pagevcenter">
      <div className="container">
        <div class="sa-a-gridview-wrapper">
          <img
            src="/images/suiteAlliance/logo-alliance.png"
            alt=""
            width="260px"
          />
          <div class="sa-a-gridview">
            <div>
              <h1>A Trusted, Vetted Partner Network</h1>
              <p>
                Join a network of carefully vetted housing operators selected
                for their commitment to quality, safety, and service.
              </p>
              <p>
                SuiteAlliance is a network of corporate housing providers, each
                focused on developing housing options in key locations and
                delivering outstanding service to their clients. These providers
                are essential to the industry and enable us to meet our
                customers' needs wherever they travel.
              </p>
              <p>
                We appreciate their dedication to growth and their unwavering
                commitment to providing high-quality accommodations.
              </p>
              <div class="btns">
                <button
                  type="button"
                  class="btn btn-black"
                  onClick={() => bookForDemo()}
                >
                  Book a Demo{" "}
                  <img
                    src="/images/suiteAlliance/up-right-arrow.svg"
                    alt=""
                    width="100%"
                  />
                </button>
                <button type="button" class="btn">
                  <i class="fa fa-play"></i> View Intro Video{" "}
                </button>
              </div>
            </div>
            <div class="itemview">
              <div class="item">
                {/* <h2>40+</h2> */}
                <p>50 states, over 100 cities and over 40 unique companies</p>
              </div>
              <div class="item">
                {/* <i class="fa-solid fa-handshake-simple"></i> */}
                <img
                  src="/images/suiteAlliance/shake-hands.svg"
                  alt=""
                  width="100%"
                />
                Providing tools and resources to enhance corporate housing
                operations.
              </div>
              <div class="item">
                <img
                  src="/images/suiteAlliance/item-img.jpeg"
                  alt=""
                  width="100%"
                />
              </div>

              <div class="item ">
                <ul>
                  <li>
                    {" "}
                    <a href="#"> 1</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">2</a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="#">3</a>{" "}
                  </li>
                </ul>
                <p>Effortless Onboarding & Collaboration</p>
              </div>
              <div class="item">
                <h3>Expanding Reach, Stronger Together</h3>
                Enhancing corporate housing through partnerships.
              </div>
            </div>
          </div>
        </div>

        {/* Booking request modal */}
        <div
          className="modal fade "
          data-backdrop="static"
          id="bookForDemo"
          role="dialog"
        >
          <div className="modal-dialog">
            <div className="modal-content rm-border-radius">
              <div className="modal-header border-0">
                <button
                  type="button"
                  onClick={closeModal}
                  className="close btn"
                  data-dismiss="modal"
                  aria-label=""
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
              {!state.successMessage ? (
                <div className="modal-body">
                  <h2>Book Your Demo</h2>
                  <form>
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        value={state.name}
                        name="name"
                        id="name"
                        className=" form-control"
                        onChange={(e) => handleInput(e.target.value, "name")}
                      />
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={state.email}
                        name="email"
                        id="email"
                        className=" form-control"
                        onChange={(e) => handleInput(e.target.value, "email")}
                      />
                    </div>
                    <div className="form-group">
                      <label>Company Name</label>
                      <input
                        value={state.companyName}
                        name="companyName"
                        id="companyName"
                        className=" form-control"
                        onChange={(e) =>
                          handleInput(e.target.value, "companyName")
                        }
                      />
                    </div>
                    <div className="form-group">
                      <label>Message</label>
                      <textarea
                        value={state.message}
                        name="message"
                        id="message"
                        rows="5"
                        cols="40"
                        className=" form-control"
                        onChange={(e) => handleInput(e.target.value, "message")}
                      />
                    </div>
                    {state.loader ? (
                      <div
                        className="text-center"
                        style={{
                          fontSize: "20px",
                        }}
                      >
                        <i className="fa fa-spinner fa-spin" />
                        Please wait ...!
                      </div>
                    ) : (
                      <button className="btn btn-dark" onClick={submitBtn}>
                        Submit
                      </button>
                    )}
                  </form>
                </div>
              ) : (
                <div className="modal-body">
                  <div className="alert alert-success">
                    Your demo request has been submitted. We will contact you
                    shortly to schedule your session.
                  </div>

                  {/* <button
                  className="btn btn-dark rounded-5 mt-3 mb-4"
                  onClick={() => sendSuccessmail()}
                >
                  Ok
                </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuiteAllianceDemoPage;
